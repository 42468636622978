import * as React from "react"
// import {graphql} from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../ui/form/ContactForm"

const Privacy = () => {
  return (
    <Layout>
      <SEO title={`Privacy - Digimeth.net`} />
      <section className="typical__padding">
        <div className="site__container policy">
          <h1>Privacy Policy</h1>
          <p>
            In order to show our firm position to conduct the business according
            to world standards, we have updated our Data Protection Policy in
            accordance with the new General Data Protection Regulation (GDPR)
            which entered into force on May 25, 2018. We believe that the
            correct business relationship is built only on the basis of honesty
            and trust. Therefore, the confidentiality of Your information is
            extremely important for us. We take all measures to ensure the
            safety of your data.
          </p>

          <h2>Data Protection Policy</h2>
          <p>
            This Policy contains provisions applicable to this website www.digimeth.net
            The controller and the processor of the personal data of the users on the
            website www.digimeth.net is the company DIGITALMETHOD TECH LTD registered in
            England (Company number 14345341) and is having its registered address
            at 167-169 Great Portland Street, Fifth Floor, London, United Kingdom, W1W 5PF
            (hereinafter referred to as the "Company" or "We"). Personal data subjects are
            visitors of this website and/ or persons who use the functionality of this
            website (hereinafter referred to as "Users" or "You"). «The Company» and «the User»
            are together referred to as «Parties», and «the Party» when being mentioned
            separately. This Policy explains how we use and protect any personal data we
            collect about this website`s users.
          </p>
          <p>
            We comply with the principles established by the General Data Protection Regulation
            (Regulation (EU) 2016/679), namely, personal data:
          </p>
          <p>
            <ol>
              <li>are processed legally, honestly and "transparently" by us;</li>
              <li>are collected for certain, explicit and legitimate purposes and are not processed further in a way that is incompatible with these purposes ("purposes limitation");</li>
              <li>are adequate, appropriate and limited to what is necessary for the purposes for which they are processed ("data minimization");</li>
              <li>are accurate and, if necessary, updated; every reasonable step should be taken to ensure that personal data which were inaccurate, taking into account the purposes for which they were processed, were erased or corrected without delay ("accuracy");</li>
              <li>are stored in a form that allows identification of users no longer than it is necessary for the purposes for which personal data are processed; ("limitation of storage");</li>
              <li>are processed in a way that provides proper protection of personal data, including protection from unauthorized or illegal processing, as well as from accidental loss, destruction or damage using appropriate technical or organizational measures ("integrity and confidentiality").</li>
            </ol>
          </p>

          <h2>Collection of Personal Data</h2>
          <p>
            Personal data refers to data about an individual who can be identified from
            either that particular data or from that data and other information that the
            Company has or is likely to have access to. Such personal data shall also
            refer to the data which is already in the Company’s possession or the data to
            be collected by the Company. This Privacy Policy supplements but does not
            supersede nor replace any other consent that the Client may have previously
            provided to the Company, nor does it affect any right that the Company may have
            at law in connection with the collection, use, disclosure and/or retention of
            the Client’s personal data.
          </p>
          <p>
            The Company only collects personal data which is knowingly and specifically
            supplied to it by the Client for the purpose as specified prior to the supply
            by the Client, that is necessary to provide the Services or that is necessary
            for compliance with a legal obligation. Without limitation, this can occur during
            or before the Client:
          </p>
          <br/>
          <p>
            <ul>
              <li>
                sets up an Account;
              </li>
              <li>
                uses our Services;
              </li>
              <li>
                provides any feedback or complaints with regard to the Services through the Website;
              </li>
              <li>
                uploads or submits any material through the Website; and/or participates in any
                marketing activity held by the Company.
              </li>
            </ul>
          </p>
          <p>
            Commonly collected personal data may include the following:
          </p>
          <p>
            <ul>
              <li>
                the Client’s name, email and postal addresses, telephone number, country of
                residence, nationality, age, gender, date of birth, copy of passport,
                business information, bank account information and other payment information;
              </li>
              <li>
                details of any transaction the Client makes using the Services;
              </li>
              <li>
                and/or details of any correspondence, queries, or any other forms of
                communication (i.e. telephone, email etc.) the Client makes with the
                Company regarding the Services.
              </li>
            </ul>
          </p>
          <br/>
          <p>
            In addition to personal data provided to the Company, certain information
            related to the Client that is not considered personal data may also be collected.
            The Company collects this information to improve its Website and Services.
            Such non-personal data may include information such as details of the Client’s
            operating system, domain name, IP address, the internet browser used, details
            of the Client’s interaction with the Website and the details of any website the
            Client has linked from to the Website.\
          </p>

          <h2>Use of Cookies</h2>
          <p>
            The Services and Website may use data collection devices such as
            “cookies” and other technologies such as pixel tags and web beacons
            to personalize a Client’s online experience.
          </p>

          <h2>Use of Personal Data</h2>
          <p>
            The Company collects personal data from existing and prospective
            clients and employees for various reasons. Any personal data will not
            be further processed in a manner that is incompatible with the purpose
            as stated before processing, for the performance of the Services,
            or to comply with a legal obligation. Without limitation, these purposes
            can include:
          </p>
          <p>
            <ul>
              <li>
                confirming the Client’s identity during the creation of his/its Account
                and to ensure the Client’s eligibility to use the Services provided;
              </li>
              <li>
                communicating with you and responding to your queries;
              </li>
              <li>
                improving the Services and the Client’s experience on the Website;
              </li>
              <li>
                providing you with information and updates on selected products and services
                that the Company thinks may be of interest to you;
              </li>
              <li>
                administering and processing credit transactions in connection with the
                Client’s use of the Services;
              </li>
              <li>
                analyzing the effectiveness of our advertisements and promotions;
              </li>
              <li>
                customising the Client’s Website experience, as well as evaluating
                statistics on Website activity, such as what time you visited it, whether
                you have visited it before and what website referred you to it;
              </li>
              <li>
                conducting required anti-money laundering checks;
              </li>
              <li>
                notifying you of any changes to the Services that may be relevant to you;
              </li>
              <li>
                conducting surveys, research and statistical analysis with the aim of
                helping the Company monitor and improve the Services;
              </li>
              <li>
                supplying the Client with information that is relevant to the Client’s
                existing relationship with the Company; and/or
              </li>
              <li>
                any other purposes as permitted by applicable laws.
              </li>
            </ul>
          </p>
          <p>
            Where the Company collects personal data for a specific purpose, the Company will not keep it for longer than is necessary to fulfil that purpose, unless we have to keep it for legitimate business or legal reasons.
          </p>
          <br/>
          <p>
            We are allowed to process your personal data on the following lawful grounds:
          </p>
          <br/>
          <p>
            <ul>
              <li>
                as necessary to perform our contract with you for our Services;
              </li>
              <li>
                in order to comply with our legal obligations;
              </li>
              <li>
                as necessary for our legitimate interests. This means we have good, sensible,
                practical reasons for processing your data in the interests of the Company. To do
                so, we have considered the impact on your interests and rights, and have in place
                appropriate safeguards to protect them.
              </li>
            </ul>
          </p>

          <h2>Sharing your personal data</h2>
          <p>
            The Company takes securing your personal data and preserving your privacy
            very seriously. The Company will not offer, publish or share your personal
            data with third parties, without seeking the Client’s explicit permission,
            unless the Company expressly states otherwise elsewhere in this Privacy Policy,
            the Terms or the agreement between the Company and the Client, or the Company
            is obliged by law. The Company will never sell your data.
          </p>
          <br/>
          <p>
            If agreed upon, or based on law, the Company may process, use and disclose
            your personal information with third parties. Such third parties may be based
            abroad and may include but are not limited to the following:
          </p>
          <br/>
          <p>
            <ul>
              <li>
                third parties providing regulated financial services where we provide related
                administration services and customer services, such as Currencycloud;
              </li>
              <li>
                regulatory authorities and enforcement agencies;
              </li>
              <li>
                any court of law or any relevant party in connection with any claim or
                legal proceeding;
              </li>
              <li>
                contractors, service providers, consultants, auditors and advisors on a
                need to know basis;
              </li>
              <li>
                advertising, marketing and promotional agencies to help us deliver and analyze
                the effectiveness of our advertising campaigns and promotions;
              </li>
              <li>
                our subsidiaries, parent company or related companies;
              </li>
              <li>
                professional service providers;
              </li>
              <li>
                third parties required to process the Services for you or otherwise provide
                any Services to you;
              </li>
              <li>
                web analytics tool providers, such as Google.
              </li>
            </ul>
          </p>
          <br/>
          <p>
            We may share your personal data for the following reasons:
          </p>
          <p>
            <ul>
              <li>
                reasons provided under ‘using your personal data’;
              </li>
              <li>
                to enforce any provision of the Terms and/or Agreement;
              </li>
              <li>
                to protect the rights or property of any member of the Company (including but
                not limited to any intellectual property rights);
              </li>
              <li>
                to conduct investigations in respect of any possible breach of applicable laws
                (only if legally obliged); and/or
              </li>
              <li>
                to comply with a court order or other legal or regulatory requirements in the
                jurisdictions we operate (only if legally obliged).
              </li>
            </ul>
          </p>
          <p>
            In situations where a third party under contract with the Company collects and/or receives personal data about you, the Company will require the third party to act according to laws regarding personal data and to exercise reasonable care in protecting your information.
          </p>
          <p>
            You are responsible for providing accurate and complete information about yourself and any other person whose personal information you provide us, and to notify us (either by the Website or by letter) if there are any changes to your personal information.
          </p>


          <h2>Security</h2>
          <p>
            The Company endeavors to take all reasonable steps to protect your personal
            data and to store it securely. However, no method of transmission over the
            Internet or method of electronic storage is 100% secure. While security
            cannot be guaranteed, the Company strives to protect your personal data and
            are constantly reviewing and enhancing our security protocols to ensure that
            your personal data is not subject to unnecessary risks. In using the Services,
            you accept the inherent security implications of engaging in transactions
            online over the internet and you agree that you will not hold the Company
            responsible for any breach of security unless we have been grossly negligent
            or in willful default.
          </p>

          <h2>Amendments to Personal Data / opt-out</h2>
          <p>
            If you wish to access, correct (including update) and remove the personal
            data held by the Company or you do not want your personal data to be
            shared according to this Privacy Policy, you may contact us as follows:
          </p>
          <br/>
          <p>
            by e-mail to:{' '}
            <span>
              <a style={{ color: "#437873" }} href="mailto:contact@digimeth.net">contact@digimeth.net</a>
            </span>
          </p>
          <br/>
          <p>
            or by postal mail to:
            The Data Protection Officer
            DIGITALMETHOD TECH LTD
            167-169 Great Portland Street,
            Fifth Floor,
            London,
            United Kingdom,
            W1W 5PF.
            You may also decline to receive e-mails by clicking on ‘unsubscribe’ in any marketing e-mail you receive.
            Rights to amend this Privacy Policy
          </p>
          <br/>
          <p>
            The Company reserves the right to update this Privacy Policy at any time to
            take into account any changes in law or for any other reason. If we update
            this Privacy Policy, we will post a new version on the Website. We will also
            post a notice of the changes on our Website or notify you by email (sent to
            the email address specified in your Account). We encourage you to periodically
            review this page for the latest information on our privacy practices.
          </p>
          <br/>
          <p>
            Your continued use of the Website and Services after any changes to this
            Privacy Policy constitutes your consent to any such changes, to the extent
            such consent is not otherwise provided. If there is a conflict between two
            versions of the Privacy Policy to which you have agreed or been deemed to agree,
            the more recent version shall take precedence unless it is expressly stated otherwise.
          </p>

          <h2>Policy concerning Children</h2>
          <p>
            Digitalmethod does not provide services to anyone under the age of 18.
          </p>
          <h2>Governing law and Jurisdiction</h2>
          <p>
            This Privacy Policy shall be governed by and construed in accordance
            with the laws of the United Kingdom.
          </p>
          <h2>Contact us</h2>
          <p>
            If you wish to communicate with us about anything related to this
            Privacy Policy, you may contact us by email at{" "}
            <span>
              <a style={{ color: "#437873" }} href="mailto:contact@digimeth.net">contact@digimeth.net</a>
            </span>
          </p>
        </div>
      </section>
      <section
        id="contact"
        className="typical__padding"
        style={{ marginTop: "80px" }}
      >
        <div className="site__container">
          <ContactForm />
        </div>
      </section>
    </Layout>
  )
}

export default Privacy
